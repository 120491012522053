exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-animations-tsx": () => import("./../../../src/pages/work/animations.tsx" /* webpackChunkName: "component---src-pages-work-animations-tsx" */),
  "component---src-pages-work-books-tsx": () => import("./../../../src/pages/work/books.tsx" /* webpackChunkName: "component---src-pages-work-books-tsx" */),
  "component---src-pages-work-magazine-illustration-tsx": () => import("./../../../src/pages/work/magazine-illustration.tsx" /* webpackChunkName: "component---src-pages-work-magazine-illustration-tsx" */),
  "component---src-pages-work-magazine-layout-tsx": () => import("./../../../src/pages/work/magazine-layout.tsx" /* webpackChunkName: "component---src-pages-work-magazine-layout-tsx" */),
  "component---src-pages-work-royality-tv-tsx": () => import("./../../../src/pages/work/royality-tv.tsx" /* webpackChunkName: "component---src-pages-work-royality-tv-tsx" */),
  "component---src-pages-work-social-media-tsx": () => import("./../../../src/pages/work/social-media.tsx" /* webpackChunkName: "component---src-pages-work-social-media-tsx" */),
  "component---src-pages-work-valharet-tsx": () => import("./../../../src/pages/work/valharet.tsx" /* webpackChunkName: "component---src-pages-work-valharet-tsx" */)
}

